var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.productCategories.length
    ? _c(
        "div",
        [
          _c("context-title", {
            attrs: { passedActions: _vm.titleBarActions, title: "Upsell Edit" },
          }),
          _c("line-item", { staticClass: "height-xs--4" }),
          _c(
            "line-item",
            { staticClass: "height-xs--12 row no-gutters align-items-center" },
            [
              _c("div", { staticClass: "col-12 block-xs--lg-left" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.upsellItem.title) +
                    " is an upsell item to " +
                    _vm._s(_vm.activeItems.length) +
                    " products. "
                ),
              ]),
            ]
          ),
          _c(
            "line-item",
            { staticClass: "height-xs--7 row no-gutters align-items-center" },
            [
              _c("div", { staticClass: "text--capped col offset-7" }, [
                _vm._v(" Attached (" + _vm._s(_vm.activeItems.length) + ") "),
              ]),
            ]
          ),
          _c("iexplorer", {
            attrs: {
              children: _vm.children,
              activeItems: _vm.activeItems,
              editMode: _vm.editMode,
              struct: { title: { value: (el) => el.inventoryItemName } },
              mode: "check",
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }