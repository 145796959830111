<template>
    <div v-if="productCategories.length">
        <context-title 
            :passedActions="titleBarActions" 
            title="Upsell Edit" />

        <line-item class="height-xs--4"/>

        <line-item class="height-xs--12 row no-gutters align-items-center">
            <div class="col-12 block-xs--lg-left">
                {{ upsellItem.title }} is an upsell item to {{ activeItems.length }} products.
            </div>
        </line-item>

        <line-item class="height-xs--7 row no-gutters align-items-center">
            <div class="text--capped col offset-7">
                Attached ({{ activeItems.length }})
            </div>
        </line-item>

        <iexplorer
            :children="children"
            :activeItems="activeItems"
            :editMode="editMode"
            :struct="{title: {value: el => el.inventoryItemName}}"
            mode="check"
        />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { toggleItem } from 'helpers'
import contextTitle from '../components/context_title.vue'
import lineItem from '../components/line_item.vue'
import iexplorer from '../components/explorer/iexplorer.vue'

export default {
    name: 'StoreSettingsUpsell',
    computed: {
        titleBarActions() {
            const reset = () => {
                this.activeItems = []
                this.setActiveItems()
                this.editMode = false
            }

            if (this.editMode) {
                return [
                    {type: 'cancel', display: 'Cancel', run: reset},
                    {type: 'proceed', display: 'Save', run: () => {
                        this.saveUpsellItemProducts({id: this.upsellItem.id, products: this.activeItems})
                        reset()
                    }},
                ]
            }

            return [
                {type: 'edit', display: 'Edit', run: () => this.editMode = true}
            ]
        },
        children() {
            const ret = this.productCategories
                .map(category => {
                    category.children = category.children
                        .map(this.assignHandlers)

                    return category
                })

            if (!this.activeItems) {
                this.activeItems = []
                this.setActiveItems()
            }

            return ret
        },
        upsellItem() {
            const itemId = this.$route.params.product
            return this.upsellItems.find(item => item.id === Number(itemId))
        },
        ...mapGetters([
            'upsellItems',
            'productCategories',
        ]),
    },
    data() {
        return {
            activeItems: false,
            editMode: false,
        }
    },
    methods: {
        setActiveItems() {
            this.productCategories.forEach(category => {
                category.children
                    .forEach(product => {
                        const productId = product.id

                        if (this.upsellItem.products.find(el => el === productId)) {
                            this.activeItems.push(productId)
                        }
                    })
            })
        },
        assignHandlers(item) {
            item.onClick = item => {
                if (this.editMode) {
                    this.activeItems = toggleItem(this.activeItems, item.id)
                }
            }

            return item
        },
        ...mapActions([
            'saveUpsellItemProducts',
        ]),
    },
    components: {
        contextTitle,
        lineItem,
        iexplorer,
    },
}
</script>

<style lang="scss">
</style>
